import { render, staticRenderFns } from "./AppPlugins.vue?vue&type=template&id=81e4758c&"
import script from "./AppPlugins.vue?vue&type=script&lang=js&"
export * from "./AppPlugins.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports