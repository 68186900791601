<template>
  <div>
     <v-dialog persistent v-model="SharePluginDialog" max-width="400">
      <v-card max-height="650px">
        <v-card-title class="overline">Share Plugin
        </v-card-title>
        <v-card-subtitle>
          <v-icon>
            {{SharingPlugin.Icon}}
          </v-icon>
          {{SharingPlugin.Name}}
        </v-card-subtitle>
        <v-card-text>
          <v-select :items="OtherApps" label="App to Share With" item-text="id" v-model="SharewithApp" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelPluginShare()" dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="SharePlugin(SharingPlugin)" dark color="success">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
          </v-dialog>
     <v-list class="transparent">
        <v-virtual-scroll 
                width="100%"
                  class="transparent"
                    :items="AppPlugins"
                    :item-height="35"
                    :height="250"
                  >
                  <template v-slot:default="{ item }">
                    <v-list-item >
                      <v-list-item-avatar>
                        <v-icon>
                          {{item.Icon}}
                        </v-icon>
                      </v-list-item-avatar>
                      
                      <v-list-item-content>
                          {{item.Name}}
                      </v-list-item-content>
                      
                      <v-list-item-content>
                        {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                      </v-list-item-content>
                       <v-list-item-avatar>
                        <v-icon @click="item.App_Shareable ? ActivatePluginShare(item) : ''" :color="item.App_Shareable ? 'green' : 'grey lighten-1'">
                          {{item.App_Shareable ? 'mdi-power-plug-outline' : 'mdi-power-plug-off-outline'}}
                        </v-icon>
                      </v-list-item-avatar>
                    </v-list-item>
                  </template>
          </v-virtual-scroll>
     </v-list>
      <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="ExtensionPlugins" :tab="systemguideelmnt"
            :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
              />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
export default {
    props: ['System','SystemEntities'],
    components: {RAPluginCarousel},
    data() {
        return {
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            SharePluginDialog: false,
            SharingPlugin: '',
            SharewithApp: '',
            NewTablesDialog: false,
            NewPlugin: '',
            RawPlugin: '',
            InstallingTables: [],
            EntitySecurityOptions: [{ID:1000001,Name:"Custom Roles"},{ID:1000002,Name:"Business Unit"},{ID:1000003,Name:"Warehouse"}],
        }
    },	
    computed:{
        ExtensionPlugins(){
            //['Database Builder','DBB','Social Network Builder','SNB','Website Builder','WB','General']
            //['Database Builder','DBB']
            return this.RAPluginsMatched(['Database Builder','DBB','Social Network Builder','SNB','Website Builder','WB','General']).filter(plug => {
              return !plug.Installation_Tables && !plug.Exclusively_as_Extension && !plug.Installs_Tables
            })
            // would have online store etc but in DB for now so yeah return this.RAPluginsMatched(['Database Builder','DBB','Social Network Builder','SNB','Website Builder','WB','General'])
        },
        TablePlugins(){
            return this.RAPlugins.filter(plug => {
                return plug.Installation_Tables
            }).map(plug => {
                let plugobj = JSON.parse(JSON.stringify(plug))
                let active = this.AppPluginsStored.find(obj => obj.id === plug.id)
                if(active){
                    console.log('active',active)
                plugobj.Active = Object.assign({},active)
                plugobj.Tables = ''
                plugobj.Active.Installation_Tables.map((table,tableindex) => {
                    let joiner = ''
                    if(tableindex-1+2 < plugobj.Active.Installation_Tables.length && tableindex !== 0){
                        joiner = ', '
                    }
                    plugobj.Tables = plugobj.Tables+joiner+table.DisplayName
                    if(tableindex===0 && tableindex-1+2 < plugobj.Active.Installation_Tables.length){
                       plugobj.Tables = plugobj.Tables+',' 
                    }
                    return table.DisplayName
                })
                }
                return plugobj
            })
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
        UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        PluginsPath(){
        return this.$store.state.PluginsPath
        },
        AppsPath(){
        return this.$store.state.AppsPath
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        PluginsTotal(){
        return this.AppPlugins.map(plug => {
          return plug.Price
        }).reduce((a, b) => a + b, 0)
      },
        AppPlugins(){
            return this.ActiveSuiteApp.Plugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        PickPlugin(SelectedPlugin){
            console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
        },
        RAPluginsMatched(categorymatches){
       let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id) && !plug.Exclusively_as_Extension || newappplugins.includes(plug.id) && !plug.Strictly_One_Purchase
        }).map(plug => {
              if(this.PluginDataBase[plug.id] && this.PluginDataBase[plug.id].Active){
                  plug.Owned = true
              }
              return plug
          })
          .filter(plug => {
            //okay we filter out and filter back in..just as a note we leave it. however ideally on purchase if suite level and not owned, ensure to add to Suite as well as App, that's the point
            return plug.Owned || plug.Level && plug.Level.Name === 'App'
            || !plug.Owned
          })
      },
        ActivatePluginShare(plug){
        this.SharingPlugin = plug
        this.SharePluginDialog = true
      },
      SharePlugin(plug){
        let plugobj = {
          Name: plug.Name,
          Module_Category: plug.Module_Category,
          id: plug.id,
          Active: true,
          Primary_Appid: this.ActiveSuiteApp.id
        }
        let appplug = this.ActiveSuiteApp.Plugins.find(obj => obj.id === plug.id)
        if(appplug.Installation_Tables){
          plugobj.Installation_Tables = appplug.Installation_Tables
        }
        if(plug.App_Shareable){
          this.AppsPath.doc(this.SharewithApp).collection('sharedplugins').doc(plug.id).set(plugobj).then(sharedplugdoc => {
          this.CancelPluginShare()
        })
        }
        else{
          this.CancelPluginShare()
        }
        
      },
      CancelPluginShare(){
        this.SharePluginDialog = false
        this.SharingPlugin = ''
        this.SharewithApp = ''
      },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
    }
}
</script>

<style>

</style>



